import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import useSessionStorage from 'hooks/useSessionStorage';
import { SearchIcon } from 'components/SvgIcons/SearchIcon';
import { BackIconTwo } from 'components/SvgIcons/BackIconTwo';
import { Button } from 'components/Button';
import { IProductItem } from 'lib/interface';
import ProductItem from 'components/ProductItem';
import { truncateText } from 'lib/helpers/functions/TextTruncate';
import { DeviceDetector } from 'utils/dd';
import { SearchNotFoundIcon } from 'components/SvgIcons/SearchNotFoundIcon';
import useRtl from 'hooks/useRtl';
import { LoaderIcon } from 'components/SvgIcons/LoaderIcon';
import PharmacyImage from 'components/PharmacyImage';
import { useGetCatalog } from 'queries/useGetCatalog';
import { isArray } from 'lodash';
import { categoryUrlGeneratorWithUrlKey } from 'utils/category-url';
import { createParentCategories } from 'utils/convert-algolia-facets';
import { useAlgolia } from '../../context/algolia-context';
import { trackPopinEvent } from 'utils/content-squire';

export default function AutoComplete({ show, bg = 'bg-[#f7f7f8]' }: any) {
  const { t } = useTranslation('common');
  const [querys, setQuery] = useState<any>([]);
  const [products, setProduct] = useState<any>([]);
  // const [trendingItems, setTrendingItems] = useState<any>([]);
  const [total_count, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const { data } = useGetCatalog();
  const router = useRouter();
  const [trendingCategory, setTrendingCategory] = useState<any>([]);
  const [trendingBrands, setTrendingBrands] = useState<any>([]);
  const [inputText, setInputText] = useSessionStorage<any>('searchInputState', '');
  const [showback, setShowback] = useState(false);
  const wrapperRef = useRef(null);

  const algolia = useAlgolia()!;
  const searchWithQuerySuggestion = algolia.productSuggestionIndex.search;
  const searchWithAlgolia = algolia.index.search;
  const recommendClient = algolia.recommendClient;
  const [showResults, setShowResults] = useState(false);
  const componentRef = useRef<any>(null);
  const isMobile = useContext(DeviceDetector).isMobile;
  const isRtl = useRtl();
  const mappedCatObj = createParentCategories(data, trendingCategory);
  const isWindow = typeof window !== 'undefined';

  useEffect(() => {
    const storedInputText = sessionStorage.getItem('searchInputState');
    if (storedInputText) {
      setInputText(storedInputText);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (componentRef.current && !componentRef?.current?.contains(event.target)) {
        setShowResults(false);
        if (isWindow) trackPopinEvent(window, 'ShowSearchListModalView', false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const renderProducts = useMemo(() => {
    if (loading && products.length === 0) {
      return (
        <div className='relative z-10' style={{ zIndex: 999 }}>
          <div className='flex justify-center h-[400px] items-center'>
            <LoaderIcon width='150' height='150' />
          </div>
        </div>
      );
    }

    if (products.length > 0) {
      return products.slice(0, 7).map((item: IProductItem, i: number) => (
        <div className='my-2' key={item.entity_id}>
          <ProductItem setShowResults={setShowResults} searchList product={item} className='w-[100%]' index={i + 1} />
        </div>
      ));
    }

    if (!loading && products.length === 0) {
      return (
        <div className={`flex flex-col h-[100%] my-[35%] items-center place-content-center mx-auto py-4`}>
          <SearchNotFoundIcon />
          {t('no_results_found_for', { word: `“${inputText}”` })}
        </div>
      );
    }
  }, [products, loading, inputText, setShowResults, t]);

  useEffect(() => {
    if (!router.asPath.includes('keyword')) {
      setInputText('');
    }
  }, [router.pathname]);

  const handleSearch = async () => {
    try {
      if (inputText.length > 0) {
        setShowResults(false);
        console.log(encodeURI(`/category/keyword=${inputText.replaceAll('/', '_')}`));

        router
          .push(encodeURI(`/category/keyword=${inputText.replaceAll('/', '_')}`), undefined, { shallow: true })
          .then(() => {
            window.location.reload();
          });
      }
    } catch (error) {
      console.error('Error searching:', error);
    }
  };

  const handelSearchForProducts = (textValue: string) => {
    setLoading(true);

    searchWithQuerySuggestion(textValue || '', {
      facetFilters: [],
      facets: ['*'],
      clickAnalytics: true,
    }).then(({ hits }: any) => {
      setQuery(hits);
      if (!textValue) {
        recommendClient
          .getTrendingFacets([
            {
              indexName: algolia.index.indexName,
              facetName: `category.name.${isRtl ? 'ar' : 'en'}`,
              maxRecommendations: 10,
            },
          ])
          .then(({ results }) => {
            setTrendingCategory(results[0]?.hits);
          })
          .catch((err) => {
            console.log(err);
          });

        recommendClient
          .getTrendingFacets([
            {
              indexName: algolia.index.indexName,
              facetName: `attributes.brand.${isRtl ? 'ar' : 'en'}`,
              maxRecommendations: 4,
            },
          ])
          .then(({ results }) => {
            setTrendingBrands(results[0]?.hits);
          })
          .catch((err) => {
            console.log(err);
          });
      }

      if (textValue) {
        searchWithAlgolia(textValue, {
          facetFilters: [],
          facets: ['*'],
          clickAnalytics: true,
        }).then(({ hits, nbHits }: any) => {
          setProduct(hits);
          setTotalCount(nbHits);
          setLoading(false);
        });
      }
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement> | any) => {
    if (isMobile && e.target.value) {
      router.push('/search-results');
      setInputText(e.target.value);
    } else {
      setShowResults(true);
      setInputText(e.target.value);
      handelSearchForProducts(e.target.value);
    }
  };

  const onChecked = (data: any) => {
    setLoading(true);
    setShowResults(false);
    if (isWindow) trackPopinEvent(window, 'ShowSearchListModalView', true);
    router.push(categoryUrlGeneratorWithUrlKey(data?.url_key)).then(() => {});
  };

  return (
    show && (
      <div className='p-2 relative flex flex-col w-[100%] items-center'>
        <div
          className={`relative rounded-lg border-transparent px-2 py-3 flex items-center autocomplete-ui ${bg}`}
          ref={wrapperRef}
          id='apollo-autocomplete'
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <SearchIcon />
          <div>
            <input
              className='border-none bg-transparent mx-2 placeholder:text-body placeholder:text-[rgba(60,60,67,0.6)] outline-none w-[100%] text-dark-gray'
              placeholder={t('search_any_product') || ''}
              value={inputText}
              onChange={handleInputChange}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSearch();
                }
              }}
              onFocus={(e) => {
                setShowback(true);
                if (isMobile) {
                  router.push('/search-results');
                }
                if (showResults === false) {
                  if (isWindow) {
                    trackPopinEvent(window, 'ShowSearchListModalView', true);
                  }
                  setShowResults(true);
                  handleInputChange(e);
                }
              }}
              name='main_search'
              autoComplete='off'
            />
          </div>
          {showback && (
            <span
              onClick={(e) => {
                setInputText('');
              }}
            >
              <BackIconTwo className='ml-[95px] rtl:ml-0 rtl:mr-[95px] cursor-pointer rtl:rotate-180' />
            </span>
          )}
        </div>
        {showResults && !isMobile && (
          <div
            ref={componentRef}
            className={`absolute ${bg} min-w-[530px] shadow-md max-w-[530px] max-h-[885px] min-h-fit pb-[10%] top-12 mt-3 rounded-md border-0 z-[100]`}
          >
            <div className='w-[100%] bg-white px-2 h-[49px] flex flex-row items-center gap-2 rounded-tl-md shadow-md rounded-tr-md'>
              {inputText &&
                querys?.slice(0, 3)?.map((item) => {
                  return (
                    <div
                      onClick={() => {
                        handelSearchForProducts(item?.query);
                        setInputText(item?.query);
                      }}
                      key={item.objectID}
                      className={`p-1 cursor-pointer rounded bg-[#B7B8B8] text-white`}
                    >
                      {truncateText(item?.query || '', querys?.slice(0, 3) > 2 ? 20 : 15)}
                    </div>
                  );
                })}
            </div>

            {!inputText && (
              <div className='w-[100%] px-3 h-fit flex flex-col gap-1 mt-1'>
                <p className='Montserrat text-[14px] font-[700] text-[#595959] ml-0'>{t('trending_keywords')}</p>
                {querys?.slice(0, 4)?.map((item) => {
                  return (
                    <div
                      onClick={() => {
                        handelSearchForProducts(item?.query);
                        setInputText(item?.query);
                      }}
                      key={item.objectID}
                      className={`p-0 cursor-pointer text-[14px] Montserrat font-[600] text-[#595959]`}
                    >
                      {truncateText(item?.query || '', querys?.slice(0, 3) > 2 ? 20 : 15)}
                    </div>
                  );
                })}
              </div>
            )}

            {!inputText && (
              <div className='w-[100%]  px-3 h-fit flex flex-col gap-1 border-t border-[#ECECEC] pt-4 mt-4'>
                <p className='Montserrat text-[14px] font-[700] text-[#595959] ml-0'>{t('trending_brands')}</p>
                {trendingBrands?.map((item) => {
                  return (
                    <div
                      onClick={() => {
                        handelSearchForProducts(item?.facetValue);
                        setInputText(item?.facetValue);
                      }}
                      key={item.objectID}
                      className={`p-0 cursor-pointer text-[14px] Montserrat font-[600] text-[#595959]`}
                    >
                      {truncateText(item?.facetValue || '', 47)}
                    </div>
                  );
                })}
              </div>
            )}

            {!inputText && (
              <div className='w-[100%] px-2  border-t border-[#ECECEC] pt-3'>
                <p className='Montserrat text-[14px] font-[700] text-[#595959]  mt-1'>{t('trending_categories')}</p>
                {data && isArray(data) ? (
                  <div
                    style={{
                      maxHeight: '80%',
                      overflowX: 'scroll',
                      scrollbarWidth: 'thin',
                      scrollbarColor: '#F7F7F8 transparent',
                    }}
                    className='flex gap-10 overflow-x-scroll overflow-y-hidden'
                  >
                    {[...mappedCatObj].map((item) => {
                      return (
                        <div
                          onClick={() => onChecked(item)}
                          key={item?.entity_id}
                          className='w-fit max-w-[103px] bg-white min-w-[103px] h-auto rounded-lg mt-2 cursor-pointer'
                        >
                          <div>
                            <PharmacyImage
                              imageWidth={103}
                              imageHeight={103}
                              objectFit='cover'
                              src={item?.image || '/local/image/placeholder_new.png'}
                              alt={item?.name}
                            />
                          </div>
                          <div className='group-hover:bg-[#35B050] rounded-b-lg h-[50px] w-auto flex items-center'>
                            <p className='h-[56px] font-bold text-caption-1 text-dark-gray group-hover:text-white py-3 px-[7.5px]'>
                              {item?.name}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div>
                    <LoaderIcon width='100' height='100' />
                  </div>
                )}
              </div>
            )}
            {inputText && <div className={`max-h-[100%] p-2 `}>{renderProducts}</div>}

            {inputText && (
              <div
                style={{ boxShadow: '0 -4px 6px -1px rgba(0, 0, 0, 0.1)' }}
                className='w-[100%] bg-white px-2 h-[49px] flex flex-row bottom-0 place-content-between absolute items-center rounded-bl-md rounded-br-md shadow-md'
              >
                <div>{t('results_found', { length: total_count })}</div>
                <div>
                  <Button
                    title={t('see_all') || ''}
                    onClick={() => {
                      handleSearch();
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    )
  );
}
