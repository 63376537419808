export const trackPageEvent = (win: Window, eventName: string, additionalData = {}) => {
  try {
    if (!win._uxa) {
      win._uxa = [];
    }

    // Start index from 1 since ContentSquare typically uses 1-based indices
    let customVarIndex = 1;
    Object.entries(additionalData).forEach(([key, value]) => {
      win._uxa.push(['setCustomVariable', customVarIndex, key, String(value), 4]);
      customVarIndex++;
    });

    // Track single pageview
    win._uxa.push(['trackPageview', eventName]);
  } catch (error) {
    console.error('ContentSquare tracking error:', error);
  }
};
export const trackPopinEvent = (win: Window, popinName: string, isOpen: boolean) => {
  win._uxa.push(['setQuery', win.location.search + (win.location.search ? '&' : '?') + popinName]);

  // Track pageview for opening the popin
  if (isOpen) {
    win._uxa.push(['trackPageview', win.location.pathname + win.location.hash.replace('#', '?__')]);
  }
};

export const trackPurchaseEvent = (win: Window, transactionId: string, revenue: number, currency = 'SAR') => {
  // Push transaction info into CS global object
  if (transactionId && revenue) {
    win._uxa.push([
      'ec:transaction:create',
      {
        id: transactionId, // Transaction ID (string, up to 40 characters)
        revenue: revenue.toFixed(2), // Revenue, formatted to 2 decimal places
        currency: currency, // Currency (ISO 4217 value, optional)
      },
    ]);
  }

  // Send the transaction to Contentsquare
  win._uxa.push(['ec:transaction:send']);
};
